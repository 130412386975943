import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate, graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import Fuse from "fuse.js";
import Layout from "../components/layout";
import {
  EarthworksCard,
  MasterplanCard,
  RecoverCard,
} from "../components/cards/project-cards";
import { BackButton } from "../components/buttons/buttons";
import { useDispatch, useSelector } from "react-redux";
import { setProjectData, addSubProject } from "../store/ducks/general";

import UserRoute from "../components/routes/userRoute";

const Container = styled.div``;
const TitleSection = styled.section`
  h1 {
    padding: 2rem 0 0 0;
  }
  a {
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    .back-icon {
      margin: 0 0.25rem 0 0;
    }
    &:hover {
      .back-icon {
        path {
          transition: all 250ms;
          fill: ${(props) => props.theme.colors.secondaryColors.orange};
        }
      }
    }
  }
  .title-row {
    padding: 2rem 3rem 1rem 3rem;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.uiPalette.dark.highGray};
  }
`;

const ResultSection = styled.section`
  padding: 2rem 3rem 1rem 3rem;
  h2 {
    padding: 0 0 1rem 0;
    transition: all 250ms;
    opacity: 0;
    visibility: hidden;
  }
  .contact-row {
    padding: 2rem 0;
    text-align: center;
  }
  .recover-card-row {
    display: grid;
    grid-template-columns: 10% 90%;
    transition: all 250ms;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
  .show {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .recover-card-row {
      grid-template-columns: 1fr;
    }
  }
`;

const Search = ({ location }) => {
  // Translation component
  const { t } = useTranslation();
  const searchTerm = location.state ? location.state.searchTerm : '';

  const allProjects = useSelector((state) => state.general.allProjects);
  const loginValues = useSelector((state) => state.general.loginValues);

  const dispatch = useDispatch();

  // Fuzzy search functionality. Configure settings available here https://fusejs.io/
  // const [searchQuery, setSearchQuery] = useState("");
  const [noResults, setNoResults] = useState("");

  const recoverFuse = new Fuse(allProjects.recover, {
    keys: ["title", "date"],
    threshold: 0.0,
    ignoreLocation: true,
  });
  const masterPlanFuse = new Fuse(allProjects.masterPlan, {
    keys: ["title", "date"],
    threshold: 0.0,
    ignoreLocation: true,
  });
  const earthworksFuse = new Fuse(allProjects.earthworks, {
    keys: ["title", "date"],
    threshold: 0.0,
    ignoreLocation: true,
  });

  const recoverResults = recoverFuse.search(searchTerm);
  const masterPlanResults = masterPlanFuse.search(searchTerm);
  const earthworksResults = earthworksFuse.search(searchTerm);

  const goToSubProject = (subProjectData) => {
    dispatch(setProjectData(subProjectData));
    dispatch(addSubProject(subProjectData.sub_project));
    window.localStorage.setItem(
      "auth",
      JSON.stringify({ ...loginValues, subProject: subProjectData.sub_project })
    );
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../u-collect-dashboard/dashboard");
  };

  const goToMasterPlan = (subProjectData) => {
    dispatch(addSubProject(subProjectData.sub_project));
    dispatch(setProjectData(subProjectData));
    window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../../masterPlan");
  };

  // On initial page load save search term into input field
  useEffect(() => {
    const searchFieldDesktop = document.getElementById("search-field-desktop");
    const searchFieldMobile = document.getElementById("search-field-mobile");
    searchFieldDesktop.value = searchTerm;
    searchFieldMobile.value = searchTerm;
  }, [searchTerm]);

  // If empty search terms show no results title
  useEffect(() => {
    if (recoverResults.length === 0) {
      setNoResults("show");
    } else if (recoverResults.length > 0) {
      setNoResults("");
    }
  }, [recoverResults.length]);

  // Check if user input search term
  // useEffect(() => {
  //   if (location.state !== null) {
  //     setSearchQuery(location.state.searchTerm);
  //   }
  // }, [location.state]);

  return (
    <UserRoute>
      <Layout goToSubProject={goToSubProject}>
        <Container>
          <TitleSection>
            <div className="title-row">
              <BackButton />
              <h1>{t("search-results.h1")}</h1>
            </div>
          </TitleSection>
          <ResultSection>
            <p>
              {t("search-term")} {searchTerm}
            </p>
            <ul>
              {recoverResults.map((recover, index) => {
                return (
                  <React.Fragment key={index}>
                    <br />
                    <RecoverCard
                      goToSubProject={goToSubProject}
                      projectData={recover.item}
                    />
                  </React.Fragment>
                );
              })}
            </ul>
            <ul>
              {masterPlanResults.map((masterPlan, index) => {
                return (
                  <React.Fragment key={index}>
                    <br />
                    <MasterplanCard
                      key={masterPlan.item.id}
                      projectData={masterPlan.item}
                      goToMasterPlan={goToMasterPlan}
                      role={loginValues.role}
                    />
                  </React.Fragment>
                );
              })}
            </ul>
            <ul>
              {earthworksResults.map((earthworks, index) => {
                return (
                  <React.Fragment key={index}>
                    <br />
                    <EarthworksCard
                      key={earthworks.item.id}
                      role={loginValues.role}
                      projectData={earthworks.item}
                    />
                  </React.Fragment>
                );
              })}
            </ul>
            <div className="contact-row">
              <h2 className={noResults}>{t("no-results")}</h2>
              <p>
                <Trans
                  i18nKey="no-results.description"
                  components={{
                    cta: (
                      <a href="mailto:contactsupport@asterra.io" className="text-link">
                        Contact us.
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </ResultSection>
        </Container>
      </Layout>
    </UserRoute>
  );
};
export default Search;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["common", "search", "buttons", "navigation", "project-cards"]
        }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
